<template>
  <v-main>
    <app-menu />
    
    <v-container class="my-6" cols="col-xs-12" >
      <v-alert text v-model="alert.show" :type="alert.type" dismissible>{{
        alert.message
      }}</v-alert>
      <div class="text-center">
        <v-dialog v-model="isLoad" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              Cargando...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog> 
      </div>
      <!-- <v-carousel  show-arrows-on-hover v-model="indexSlider" hide-delimiters height="100%"> 
        <v-carousel-item v-for="(item,i) in sliderImg" :key="i"  >
            <v-img contain :src="item" height="100%" width="100%"></v-img>
        </v-carousel-item>
      </v-carousel> -->
      <v-row class="container " id="" v-if="isLoad == false">
        <v-col >
          <v-row  class="mt-3" >
            <v-card ref="form" width="277">
              <v-card-title class="blue lighten-1 white--text">
                <span class="text-h4"> Mis Datos</span>
              </v-card-title>
              <v-card-text>
                <!-- <v-text-field ref="name" v-model="user.username" label="Nombre" required></v-text-field>
                  <v-text-field ref="name" v-model="user.phone2" label="Celular" required></v-text-field>
                  <v-text-field ref="name" v-model="user.value" label="No. Cliente" required></v-text-field>
                  <v-text-field ref="name" :value="formatMXN(user.so_creditlimit) + ' MXN'" 
                  label="Limite de Cr�dito" required></v-text-field>
                  <v-text-field  ref="name" :value="formatMXN(user.so_creditused) + ' MXN'" label="Cr�dito Usado" required></v-text-field> -->

                <v-card-text> 
                  <h3>Nombre <br />{{ user.username }}</h3>
                  </v-card-text>
                <v-divider color="cyan"></v-divider>
                <v-card-text><h3> Celular <br />{{ user.phone2 }}</h3></v-card-text>
                <v-divider color="cyan"></v-divider>
                <v-card-text><h3> No. Cliente <br />{{ user.value }}</h3></v-card-text>
                <v-divider color="cyan"></v-divider>
                <v-card-text><h3>
                  Limite de Cr&eacute;dito <br />{{
                    formatMXN(user.so_creditlimit) + " MXN"
                  }}</h3></v-card-text
                >
                <v-divider color="cyan"></v-divider>
                <v-card-text
                  ><h3>Cr&eacute;dito Usado <br />{{
                    formatMXN(user.so_creditused) + " MXN"
                  }}</h3></v-card-text
                >
                <v-divider color="cyan"></v-divider>
                <v-card-text
                  ><h3>Cr&eacute;dito Disponible  <br/>
                  {{formatMXN(user.so_creditlimit - Math.abs(user.so_creditused)) + " MXN"}}
                  </h3></v-card-text>
                <v-divider color="cyan"></v-divider>
              </v-card-text>
            </v-card>

          </v-row>
          <v-row> 
              <v-card ref="form" class="mt-5">
                <!-- 
                  <v-card-text>   
                    <v-text-field ref="name" v-model="user.email" label="Email" required></v-text-field>
                    <v-text-field ref="name" v-if="user.taxid != 'XAXX010101000'" 
                    v-model="user.taxid" label="RFC" required></v-text-field> 
                  </v-card-text> 
                    -->
                <v-card-title style="font-size:120px" class="blue lighten-1 white--text">
                  <span class="text-h4">Datos Fiscales</span>
                </v-card-title>
                <v-card-text>
                  <v-card-text><h3> Correo Electrónico<br />{{ user.email }}</h3></v-card-text>
                  <v-divider color="cyan"></v-divider>
                  <!-- <v-card-text v-if="user.taxid !='XAXX010101000'" > RFC <br>{{user.taxid}}    </v-card-text> -->
                  <v-card-text><h3> RFC <br />{{ user.taxid }} </h3></v-card-text>
                  <v-divider color="cyan"></v-divider>
                </v-card-text>
              </v-card>
          </v-row>
          
        </v-col>
        <v-col   md="9"  class="ml-auto mt-4 ">
              <v-card
                
                ref="form"
                elevation="3">
                <v-card-title
                  class="blue lighten-1 white--text"
                  style="text-align:center important!;"
                >
                  <span class="text-h4">Direcciones</span>
                </v-card-title>
                <template>
                  <v-container >
                    <v-row justify="center"  >
                        <v-card  v-for="user in userList" :key="user.c_location_id"
                          width="40%"
                          elevation="8"
                          class="ma-3"
                          max-width="334"
                          height="10%">
                          <v-img
                            style=""
                            elevation=""
                            src="../../public/Usuario/card.jpg"
                            height="35px"
                          ></v-img>
                          <v-card-title style="align-text:center;">{{user.locationname}}</v-card-title>
                          <v-card-text>
                            
                            <h2 class="font-weight-light mt-1">Pa&iacute;s</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                            {{ user.countryname }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Estado</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.description }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Ciudad</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.city }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Colonia</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.address2 }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Delegaci&oacute;n/Municipio</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.address3 }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Referencia</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.address4}}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2 class="font-weight-light mt-1">Calle</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.address1 }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            <h2  class="font-weight-light mt-1">Tel&eacute;fono</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.office_number }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                            
                            <h2 class="font-weight-light mt-1">C&oacute;digo Postal</h2>
                            <h4 style="font-family:Lucida, sans-serif;">
                              {{ user.postal }}
                            </h4>
                            <v-divider
                              color="to left,cyan,red"
                              class="mt-1"
                            ></v-divider>
                          </v-card-text>
                            <v-card-actions>
                              <v-tooltip bottom>
                                <template  v-slot:activator="{ on }">
                              
                                  <v-icon 
                                    v-on="on" 
                                    @click="readUser(user.c_location_id)" 
                                    color="blue" 
                                    elevation="5" 
                                    style="font-size:50px;">mdi-playlist-edit</v-icon>
                                </template>
                                <span>Editar direcci&oacute;n</span>
                              </v-tooltip>

                              <v-tooltip bottom>
                                <template  v-slot:activator="{ on }">
                              
                                  <v-icon 
                                    v-on="on" 
                                    @click="desactivaDireccion(user.c_bpartner_location_id)" 
                                    color="blue" 
                                    elevation="5" 
                                    style="font-size:40px;">mdi-delete</v-icon>
                                </template>
                                <span>Eliminar direcci&oacute;n</span>
                              </v-tooltip>


                            </v-card-actions>
                            
                        </v-card>
                    </v-row>
                    <v-dialog v-model="add" max-width="450">
                      <v-card>

                        <v-img
                          src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                          height="35px"
                        ></v-img>
                        <v-card-title>
                          <span class="text-h4">A&ntilde;adir lista</span>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            ref="addForm"
                            @submit.prevent="addAddress()"
                            class="ma-3"
                          >
                            <v-text-field
                              style="font-size:20px;"
                              color="#00E676"
                              prepend-icon="mdi-card-account-details-outline"
                              label="Alias"
                              :rules="[(v) => !!v || 'Dirección es requerido']"
                              v-model="usertoAdd.locationname"
                            >
                            </v-text-field>
                            <v-select
                              style="font-size:20px;"
                              prepend-icon="mdi-earth"
                              label="País"
                              color="#00E676"
                              v-model="usertoAdd.c_country_id"
                              :items="arrayCountry"
                              item-text="name"
                              item-value="name"
                              :rules="[(v) => !!v || 'País es requerido']"
                              @change="readRegionname(usertoAdd.c_country_id)"
                            ></v-select>
                            <v-select
                              style="font-size:20px;"
                              prepend-icon="mdi-sign-real-estate"
                              label="Estado"
                              color="#00E676"
                              :rules="[(v) => !!v || 'Estado es requerido']"
                              v-model="usertoAdd.c_region_id"
                              :items="arrayRegion"
                              item-text="name"
                              item-value="c_region_id"
                              :filter="customFilter"
                              
                            >
                            <template slot="selection" slot-scope="data">
                              {{data.item.name}} 
                            </template>
                            <template slot="item" slot-scope="data">
                              {{data.item.description}} 
                            </template>
                            </v-select>
                            <v-text-field
                              style="font-size:20px;"
                              color="#00E676"
                              prepend-icon="mdi-city"
                              label="Ciudad"
                              :rules="[(v) => !!v || 'Ciudad es requerido']"
                              v-model="usertoAdd.city"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px;"
                              color="#00E676"
                              prepend-icon="mdi-city-variant"
                              label="Colonia"
                              :rules="[(v) => !!v || 'Colonia es requerido']"
                              v-model="usertoAdd.address2"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px;"
                              type="text"
                              color="#00E676"
                              prepend-icon="mdi-home-city"
                              label="Delegación/Municipio"
                              :rules="[(v) => !!v || 'Delegación/Municipio es requerido']"
                              v-model="usertoAdd.address3"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px;"
                              color="#00E676"
                              prepend-icon="mdi-map-marker-path"
                              label="Calle"
                              :rules="[(v) => !!v || 'Calle es requerido']"
                              v-model="usertoAdd.address1"
                            ></v-text-field>
                            <v-textarea
                              rows="1"
                              style="font-size:20px;"
                              color="#00E676"
                              prepend-icon="mdi-map-marker-path"
                              label="Referencia"
                              :rules="[(v) => !!v || 'La referencia es requerida']"
                              v-model="usertoAdd.address4"
                            ></v-textarea>
                            <v-text-field
                              style="font-size:20px;"
                              type="number"
                              color="#00E676"
                              prepend-icon="mdi-cellphone"
                              label="Teléfono"
                              :rules="[(v) => !!v || 'Teléfono es requerido',
                                      (v) => (v && v.length <=10) || '¡Sólo se permiten 10 números!']"
                              v-model="usertoAdd.office_number"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px;"
                              type="number"
                              color="#00E676"
                              prepend-icon="mdi-code-tags"
                              label="Código postal"
                              :rules="[(v) => !!v || 'Código postal es requerido',
                                      (v) => (v && v.length <=5) || '¡Sólo se permiten 5 n&uacute;meros!']"
                              v-model="usertoAdd.postal"
                            ></v-text-field>
                            <v-btn block color="#00E676" style="color:white;" class="mt-4" type="submit" 
                              >Guardar</v-btn
                            >
                            <v-btn block type="reset" class="error mt-3" @click="add=false"
                              >CANCELAR</v-btn
                            >
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="edit" max-width="440"  >
                      <v-card >
                        <v-img
                          style=""
                          elevation=""
                          src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
                          height="35px"
                        ></v-img>
                        <v-card-title
                          ><span class="text-h4">Actualizar lista</span>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            ref="editForm"
                            @submit.prevent="editAddress()"
                            class="ma-3"
                          > <v-text-field
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-card-account-details-outline"
                              label="Alias"
                              :rules="[(v) => !!v || 'Alias es requerido']"
                              v-model="userToEdit.locationname"
                            ></v-text-field>
                            <v-select
                              style="font-size:20px; color:red;"
                              prepend-icon="mdi-earth"
                              label="País"
                              color="blue"
                              v-model="userToEdit.c_country_id"
                              :items="arrayCountry"
                              item-text="name"
                              item-value="c_country_id"
                              :rules="[(v) => !!v || 'País es requerido']"
                              @change="readRegionname(userToEdit.c_country_id)"
                            ></v-select>
                            <v-select
                              style="font-size:20px; color:red;"
                              prepend-icon="mdi-sign-real-estate"
                              label="Estado"
                              color="blue"
                              :rules="[(v) => !!v || 'Estado es requerido']"
                              v-model="userToEdit.c_region_id"
                              :items="arrayRegion"
                              item-text="name"
                              item-value="c_region_id"
                              :filter="customFilter"
                            >
                            <template slot="selection" slot-scope="data">
                              {{data.item.name}} 
                            </template>
                            <template slot="item" slot-scope="data">
                              {{data.item.description}} 
                            </template>
                            </v-select>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-city"
                              label="Ciudad"
                              :rules="[(v) => !!v || 'Ciudad es requerido']"
                              v-model="userToEdit.city"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-city-variant"
                              label="Colonia"
                              :rules="[(v) => !!v || 'Colonia es requerido']"
                              v-model="userToEdit.address2"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-home-city"
                              label="Delegación/Municipio"
                              :rules="[(v) => !!v || 'Delegación/Municipio  es requerido']"
                              v-model="userToEdit.address3"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-map-marker-path"
                              label="Calle"
                              :rules="[(v) => !!v || 'Calle es requerido']"
                              v-model="userToEdit.address1"
                            ></v-text-field>
                            <v-textarea
                              rows="1"
                              style="font-size:20px; color:red;"
                              type="text"
                              color="blue"
                              prepend-icon="mdi-map-marker-path"
                              label="Referencia"
                              :rules="[(v) => !!v || 'La referencia es requerida']"
                              v-model="userToEdit.address4"
                            ></v-textarea>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="number"
                              color="blue"
                              prepend-icon="mdi-cellphone"
                              label="Teléfono"
                              :rules="[(v) => !!v || 'Teléfono es requerido',
                                      (v) => (v && v.length <=10) || '¡Sólo se permiten 10 números!']"
                              v-model="userToEdit.office_number"
                            ></v-text-field>
                            <v-text-field
                              style="font-size:20px; color:red;"
                              type="number"
                              color="blue"
                              prepend-icon="mdi-code-tags"
                              label="Código postal"
                              :rules="[(v) => !!v || 'Código postal es requerido',
                                      (v) => (v && v.length <=5) || '¡Sólo se permiten 5 números!']"
                              v-model="userToEdit.postal"
                            ></v-text-field>
                            <v-btn block class="info mt-3" type="submit"
                              >ACTUALIZAR</v-btn
                            >
                            <v-btn block  class="error mt-3" @click="edit=false"
                              >CANCELAR</v-btn
                            >
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </v-container>
                </template>
                <v-tooltip bottom>
                  <template class="align-center" v-slot:activator="{on}" >
                    <v-btn style="margin-left:88%;" @click="add = true" color="red" x-large right bottom fab dark v-on="on">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar direcci&oacute;n</span>
                </v-tooltip>

                <br />

                <br />
              </v-card>
        </v-col>
      </v-row>
      
    </v-container>
  </v-main>
</template>


<script>
import axios from "axios";
import config from "../json/config.json";
import AppMenu from "../components/Menu.vue";
// import AppSlider from "../components/Slider.vue";
export default {
  name: "account",
  data() {
    return {
      sliderImg2:[],
      indexSlider: 0,
      files:null,
      imagenes:[],
      sliderImg:[],
      arrayRegion:[],
      arrayCountry:[],
      listCards:{},
      alert: { show: false },
      userList: [],
      userToEdit: {},
      usertoAdd:{},
      dialog: false,
      user: {},
      msgerror: "",
      isLoad: true,
      add: false,
      edit: false,
      
    };
  },
 
  methods: {
    formatMXN(value) {
      var formatter = new Intl.NumberFormat("en-ES", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(value);
    }
    
    ,async readUser(c_location_id) {
      await axios
        .get(config.apiAmoresens + `/user/readUser/${c_location_id}`, {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          this.userToEdit = res.data;
          this.edit = true;
        })
        .catch((err) => {
          return err;
        });
    }

    ,async desactivaDireccion(c_bpartner_location_id){
      
      await axios.get(config.apiAmoresens + `/user/disableAddress/${c_bpartner_location_id}`, {
          headers: { token: this.$cookie.get("token") },
        })
        .then((res) => {
          // this.userToEdit = res.data;
          // this.edit = true;
          // console.log(res.status);
          if(res.status == 200){
            window.location.reload();
          }

        })
        .catch((err) => {
          return err;
        });
    }    
    
    ,async editAddress() {
      this.isLoad = true;
      let valid = this.$refs.editForm.validate();
      if (valid) {
        try {
          await axios.put(
            config.apiAmoresens +
              `/user/updateAddress`,
            this.userToEdit,{headers:{'token': this.$cookie.get('token')}}
          );
          const index = this.userList.findIndex(
            (c) => c.c_location_id == this.userToEdit.c_location_id
          );
          this.userList[index] = this.userToEdit;
          this.edit = false;

        } catch (error) {
          console.log(error);
        }
      }
      // window.location.reload();
      this.isLoad = false;
    }

    ,async addAddress(){
      let valid = this.$refs.addForm.validate();
      if(valid){
        
        this.usertoAdd.c_bpartner_id = this.user.c_bpartner_id;
        this.usertoAdd.ad_user_id = this.user.ad_user_id;
        try{
          const res = await axios.post(config.apiAmoresens + `/user/insertAddress`,
            this.usertoAdd,{headers:{'token': this.$cookie.get('token')}}
          );
          this.userList.push(res.data.user);
          // console.log(res);
          // this.created()
          // await axios
          // .get(config.apiAmoresens + "/user/userByToken", {
          //   headers: { token: this.$cookie.get("token") },
          // })
          this.$refs.addForm.reset();
          this.add = false;
        }catch(error) {
          return error

        }
      }

    }
    
    ,async readRegionname(){
      // await axios.get( config.apiAmoresens + `/user/readRegionname/${c_country_id}`,
      await axios.get( config.apiAmoresens + `/user/readRegionname`,
      {headers:{'token': this.$cookie.get('token')}})
      .then(res =>{
        this.arrayRegion = res.data
      })
      .catch(err =>{
        return err
      })
    }
    
    ,async readCountry(){
      await axios.get(config.apiAmoresens + '/user/readCountry',
      {headers:{'token': this.$cookie.get('token')}})
      .then(res=>{
        this.arrayCountry = res.data
      })
      .catch(err=>{
        return err
      })
    },
    // async leerEstado(){
    //   await axios.get( config.apiAmoresens + `/user/readRegionname`,
    //   {headers:{'token': this.$cookie.get('token')}})
    //   .then(res =>{
    //     this.arrayRegion = res.data
    //   })
    //   .catch(err =>{
    //     return err
    //   })
    // },
    customFilter(item,queryText){
      const textOne = item.function.toLowerCase();
      const textTwo = item.description.toLowerCase();
      const searchText = queryText.toLowerCase();
      return(
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      )
    },
    
  },
  components: {
    "app-menu": AppMenu,  
  }
  
  ,async mounted() {
    window.scrollTo(0, 0);
    this.readCountry();
    this.readRegionname(); 
  }

  ,computed:{
    
  },

  async created() {
    // setInterval(this.changeSlider, 10000);
    this.isLoad = true;
    this.user = await axios.get(config.apiAmoresens + "/user/userByToken", {
           headers: { token: this.$cookie.get("token") },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });

      console.log(this.user.user.c_bpartner_id);
      
    /** Listado de Direcciones de la Cuenta */  
    if (this.user.status == "success"){
      this.user = this.user.user;
      try{
        const res = await axios.post(
          config.apiAmoresens + `/user/listAddressAccount`,this.user,

          {headers:{'token': this.$cookie.get('token')}});

        this.userList = res.data;

      }catch(e){
        return e
      }
    } else {
      this.$router.push("/shop/Login");
    }

    this.isLoad = false;

  },
  
};
</script>
